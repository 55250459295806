@import '../abstracts/variables';

.basic {
  &__desktop-wrapper {
    max-width: $content-desktop-width;
    padding-left: $spacer-7;
    padding-right: $spacer-7;
    margin: 0 auto;
  }
  &__link {
    color: $color-blue !important;
    text-decoration: underline !important;
  }
  &__layout {
    &_franchisor {
      margin-top: $header-height;
      width: 100%;
      min-width: $page-width-min;
      min-height: $page-height-min;
    }
    &_franchisee {
      margin-top: $header-height-franchisee;
      width: 100%;
    }
  }
  &__text{
    &_title{
      font-family: $fontFamily-default !important;
      font-size: $fontSize-md !important;
      font-weight: 500 !important;
    }
    &_paragraph{
      font-family: $fontFamily-default !important;
      font-size: $fontSize-xxs !important;
      font-weight: 400 !important;
    }
  }
  &__layout_m-t{
    margin-top: $layout-margin-top;
  }
}

ul, li {
  list-style-type: none !important;
}