@import '../abstracts/variables';

.flex {
  display: flex;

  &__column {
    flex-direction: column;
  }

  &-noWrap {
    flex-wrap: nowrap;
  }

  &__item {
    &_1 {
      flex: 1;
    }
  }

  &-justify-center {
    justify-items: center;
  }

  &-align-center {
    align-items: center;
  }
}
