$logo-mobile-width: 198px;
$logo-mobile-height: 133px;
$logo-width: 276px;
$logo-height: 185px;
$screen-md-page-padding: 120px;

$tech-work-img-max-width: 739px;

$bg-color: #fbe122;

.tech-work-page {
  font-family: $fontFamily-UniformRounded;
  background: $bg-color;
  min-height: 100%;
  color: $color-brown;
  padding: $spacer-13 $spacer-7 0 $spacer-7;

  @media (min-width: $screen-md) {
    padding: $screen-md-page-padding;
  }

  &__logo {
    width: $logo-mobile-width;
    height: $logo-mobile-height;

    @media (min-width: $screen-md) {
      width: $logo-width;
      height: $logo-height;
    }
  }

  &__header {
    font-size: $fontSize-lg;
    margin-bottom: $spacer-8;
    font-weight: 700;
    line-height: $fontSize-lg;

    @media (min-width: $screen-md) {
      font-size: $fontSize-xxlg;
      line-height: $fontSize-xxlg;
      margin-bottom: $spacer-13;
    }
  }

  &__subheader {
    font-size: $fontSize-sm;
    font-weight: 700;

    @media (min-width: $screen-md) {
      font-size: $fontSize-lg;
    }
  }

  &__text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: $spacer-8;
  }

  &__tech-work-img {
    width: 100%;
    max-width: $tech-work-img-max-width;
  }
}
