@import '../abstracts/variables';

.ant-upload.ant-upload-select-picture-card {
  min-width: $img-upload-size;
  min-height: $img-upload-size;
}

.ant-upload-list-picture-card-container {
  width: $img-upload-size;
  height: $img-upload-size;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: $img-upload-size;
  height: $img-upload-size;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  height: 100%;
}

.ant-upload-list-item-uploading-text {
  display: flex;
  justify-content: center;
}

.ant-progress-outer {
  padding-left: $spacer-4;
  padding-right:  $spacer-4;
}