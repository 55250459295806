@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Uniform Rounded';
  src: url('../../fonts/Uniform Rounded Bold.otf');
  font-weight: 700;
}
