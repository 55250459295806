@import '../abstracts/variables';

.franchisee {
  &__text {
    font-family: $fontFamily-default !important;
    font-size: $fontSize-md !important;
    font-weight: 500 !important;
    color: $color-black-text;
    &_title {
      color: $color-black-text;
      @extend .fs-md, .font-family__default, .font-weight-7;
    }
    &_default {
      @extend .fs-xs, .font-family__default;
      color: $color-black-text;
    }
    &_paragraph {
      font-family: $fontFamily-default;
      font-weight: bold;
      font-size: $fontSize-xs;
      color: $color-black-text;
      @media (max-width: $screen-lg) {
        font-size: $fontSize-xxxs !important;
      }
    }
  }
  &__date-picker{
    .ant-picker-time-panel {
      display: none;
    }
  }
}
