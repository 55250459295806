@import '../abstracts/variables';

.btn-opacity {
  &.ant-btn,
  &.ant-btn:active {
    background: $btn-color-opacity !important;
    border: none !important;
    padding: 0 $spacer-3;
    height: $btn-opacity-height;
    border-radius: $border-radius-button;
  }

  &_sm {
    &.ant-btn,
    &.ant-btn:active {
      padding: 0 5px;
    }
  }
}

.popup__btn {
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
