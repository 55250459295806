@import '../abstracts/variables';

//COLORS
.color-blue {
  color: $color-blue !important;
}

.color-whiteDark {
  color: $color-whiteDark !important;
}

.color-grayLight {
  color: $color-grayLight !important;
}

.color-gray {
  color: $color-gray !important;
}

.color-grayDark {
  color: $color-grayDark !important;
}

.color-black {
  color: $color-black !important;
}

.color-blackLight {
  color: $color-blackLight !important;
}
.color-inherit {
  color: inherit !important;
}
.color-rate-star {
  color: $color-rate-star-yellow !important;
}
.color-brown {
  color: $color-brown !important;
}

//FONTS
.font-family {
  &__default {
    font-family: $fontFamily-default !important;
  }
  &__medium {
    font-family: $fontFamily-medium !important;
  }
  &__semi-bold {
    font-family: $fontFamily-semiBold  !important;
  }
  &__bold {
    font-family: $fontFamily-bold !important;
  }
}

.font-weight {
  &-default {
    font-weight: normal;
  }
  &-4 {
    font-weight: 400;
  }
  &-5 {
    font-weight: 500;
  }
  &-6 {
    font-weight: 600;
  }
  &-7 {
    font-weight: 700;
  }
  &-8 {
    font-weight: 800;
  }
  &-9 {
    font-weight: 900;
  }
}

.fs {
  &-xxxs {
    font-size: $fontSize-xxxs !important;
  }
  &-xxs {
    font-size: $fontSize-xxs !important;
  }
  &-xs {
    font-size: $fontSize-xs !important;
  }
  &-sm {
    font-size: $fontSize-sm !important;
  }
  &-md {
    font-size: $fontSize-md !important;
  }
  &-lg {
    font-size: $fontSize-lg !important;
  }
  &-xlg {
    font-size: $fontSize-xlg !important;
  }
}

@mixin spacerBuilder($property) {
  &-0 {
    #{$property}: $spacer-0 !important;
  }
  &-1 {
    #{$property}: $spacer-1 !important;
  }
  &-2 {
    #{$property}: $spacer-2 !important;
  }
  &-3 {
    #{$property}: $spacer-3 !important;
  }
  &-4 {
    #{$property}: $spacer-4 !important;
  }
  &-5 {
    #{$property}: $spacer-5 !important;
  }
  &-6 {
    #{$property}: $spacer-6 !important;
  }
  &-7 {
    #{$property}: $spacer-7 !important;
  }
  &-8 {
    #{$property}: $spacer-8 !important;
  }
  &-9 {
    #{$property}: $spacer-9 !important;
  }
  &-10 {
    #{$property}: $spacer-10 !important;
  }
  &-11 {
    #{$property}: $spacer-11 !important;
  }
  &-12 {
    #{$property}: $spacer-12 !important;
  }
  &-13 {
    #{$property}: $spacer-13 !important;
  }
}

.m {
  @include spacerBuilder(margin);
}
.mt {
  @include spacerBuilder(margin-top);
}

.mr {
  @include spacerBuilder(margin-right);
}

.mb {
  @include spacerBuilder(margin-bottom);
}

.ml {
  @include spacerBuilder(margin-left);
}

.p {
  @include spacerBuilder(padding);
}
.pt {
  @include spacerBuilder(padding-top);
}

.pr {
  @include spacerBuilder(padding-right);
}

.pl {
  @include spacerBuilder(padding-left);
}

.pb {
  @include spacerBuilder(padding-bottom);
}

@media (max-width: 400px) {
  .text-header {
    width: 260px;
  }
}

.box-shadowed {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.rounded {
  background: $color-white;
  border: 1px solid $color-whiteDark;
  box-sizing: border-box;
  border-radius: $border-radius;
}

@media (max-width: $screen-md) {
  .rounded > .ant-card-body {
    padding: $spacer-6;
  }
}

.input:focus {
  outline: none;
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.height-full {
  height: 100%;
}
.width-full {
  width: 100%;
}

.width-max-full {
  max-width: 100%;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.t-align-c {
  text-align: center;
}

.t-align-e {
  text-align: end;
}

.t-align-s {
  text-align: start;
}

.t-transform-c {
  text-transform: capitalize;
}

.word-break {
  word-break: break-word;
}
.white-space-nowrap {
  white-space: nowrap;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}
