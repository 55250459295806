@import '../abstracts/variables';

.map {
  &__place-picker {
    width: 100%;
    height: 50vh;
    &_skeleton {
      @extend .map__place-picker;
      background: $color-whiteDark;
    }
  }

  &__input {
    width: 25%;
    margin: $spacer-4 0 0 $spacer-3;
  }
}
