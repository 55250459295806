@import '../abstracts/variables';

.ant-select-selector {
  border-radius: $border-radius !important;
}
.ant-form-item {
  margin-bottom: $form-error-field-height;
  flex-direction: column;
}
.ant-form-item-label,
.ant-form-item-label-require,
.ant-form-item-label > label {
  font-family: $fontFamily-default;
  font-size: $fontSize-xxs !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: $fontSize-xxs !important;
  min-height: $form-error-field-height;
}
.ant-form-item-has-error {
  margin-bottom: 0 !important;
}

.ant-form-item-with-help {
  padding-bottom: $spacer-4 !important;
}

.ant-form-item-label {
  display: flex;
  > label::after {
    content: unset;
  }
}
.ant-input,
.ant-input-affix-wrapper {
  border-radius: $border-radius;
}

.ant-btn {
  border-radius: $border-radius-button;
}

.ant-menu-item {
  min-width: $menu-item-width-min;
  margin: 0 $spacer-4 !important;
}
.ant-menu {
  padding: 0 $header-padding-side;
}

.ant-btn-link {
  color: inherit;
}

.ant-modal {
  width: $modal-width !important;
  border-radius: $border-radius !important;
}
.ant-table-row {
  cursor: pointer;
}

.ant-input-number {
  @extend .ant-input;
  width: 100%;
}

.ant-tabs-nav-operations,
.ant-tabs-dropdown {
  display: none !important;
}

.ant-list {
  .ant-collapse:first-child {
    border-top: 1px solid #d9d9d9 !important;
  }

  .ant-collapse {
    border-top: none !important;
  }
}

.ant-notification-notice {
  border-radius: $border-radius-button;
  border: 1px solid $color-warning;
}
